import {locationIsIneligible} from 'client/utils/view-helpers/fulfillment-view-helpers';

import {getActiveCartForReservationStepper_carts_fulfillmentLocation_fulfillmentStoreLocation} from '../../../../autogen/getActiveCartForReservationStepper';

export const isEcommerceInactive = (
    fulfillmentStoreLocation:
        | getActiveCartForReservationStepper_carts_fulfillmentLocation_fulfillmentStoreLocation
        | null
        | undefined
) => locationIsIneligible(fulfillmentStoreLocation?.ecommerceStatus);

export const isCheckoutFlow = (pathName: string) => {
    const listOfCartViews = [
        '/checkout/cart',
        '/checkout/grocery-options',
        '/checkout/wic-options',
        '/checkout/substitutions'
    ];

    return listOfCartViews.includes(pathName);
};
